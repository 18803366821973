import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && _indexOfInstanceProperty(e).call(e, p) < 0) t[p] = s[p];

  if (s != null && typeof _Object$getOwnPropertySymbols === "function") for (var i = 0, p = _Object$getOwnPropertySymbols(s); i < p.length; i++) {
    if (_indexOfInstanceProperty(e).call(e, p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import warning from '@douyinfe/semi-foundation/lib/es/utils/warning';

const generateOption = (child, parent, index) => {
  const childProps = child.props;

  if (!child || !childProps) {
    return null;
  }

  const option = _Object$assign(_Object$assign({
    value: childProps.value,
    // Drop-down menu rendering priority label value, children, value in turn downgrade
    label: childProps.label || childProps.children || childProps.value,
    _show: true,
    _selected: false,
    _scrollIndex: index
  }, childProps), {
    _parentGroup: parent
  });

  return option;
};

const getOptionsFromGroup = selectChildren => {
  let optionGroups = [];
  let options = [];
  const emptyGroup = {
    label: '',
    children: [],
    _show: false
  }; // avoid null
  // eslint-disable-next-line max-len

  let childNodes = React.Children.toArray(selectChildren);
  childNodes = _filterInstanceProperty(childNodes).call(childNodes, childNode => childNode && childNode.props);
  let type = '';
  let optionIndex = -1;

  _forEachInstanceProperty(childNodes).call(childNodes, child => {
    if (child.type.isSelectOption) {
      type = 'option';
      optionIndex++;
      const option = generateOption(child, undefined, optionIndex);
      emptyGroup.children.push(option);
      options.push(option);
    } else if (child.type.isSelectOptionGroup) {
      type = 'group'; // Avoid saving children (reactNode) by... removing other props from the group except children, causing performance problems
      // eslint-disable-next-line prefer-const

      let _a = child.props,
          {
        children
      } = _a,
          restGroupProps = __rest(_a, ["children"]);

      children = React.Children.toArray(children);

      const childrenOption = _mapInstanceProperty(children).call(children, option => {
        optionIndex++;
        return generateOption(option, restGroupProps, optionIndex);
      });

      const group = _Object$assign(_Object$assign({}, child.props), {
        children: childrenOption,
        key: child.key
      });

      optionGroups.push(group);
      options = _concatInstanceProperty(options).call(options, childrenOption);
    } else {
      warning(true, '[Semi Select] The children of `Select` should be `Select.Option` or `Select.OptionGroup`');
    }
  });

  if (type === 'option') {
    optionGroups = [emptyGroup];
  }

  return {
    optionGroups,
    options
  };
};

export { generateOption, getOptionsFromGroup };