import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import BaseFoundation from '../base/foundation';
import isEnterPress from '../utils/isEnterPress';
import warning from '../utils/warning';

class CheckboxFoundation extends BaseFoundation {
  constructor(adapter) {
    super(_Object$assign({}, adapter));
    this.clickState = false;

    this.handleFocusVisible = event => {
      const {
        target
      } = event;

      try {
        if (this.clickState) {
          this.clickState = false;
          return;
        }

        if (target.matches(':focus-visible')) {
          this._adapter.setFocusVisible(true);
        }
      } catch (error) {
        warning(true, 'Warning: [Semi Checkbox] The current browser does not support the focus-visible');
      }
    };

    this.handleBlur = () => {
      this.clickState = false;

      this._adapter.setFocusVisible(false);
    };
  }

  init() {
    const {
      children,
      extra,
      extraId,
      addonId
    } = this.getProps();

    if (children && !addonId) {
      this._adapter.setAddonId();
    }

    if (extra && !extraId) {
      this._adapter.setExtraId();
    }
  }

  getEvent(checked, e) {
    const props = this.getProps();
    const cbValue = {
      target: _Object$assign(_Object$assign({}, props), {
        checked
      }),
      stopPropagation: () => {
        e.stopPropagation();
      },
      preventDefault: () => {
        e.preventDefault();
      },
      nativeEvent: {
        stopImmediatePropagation: () => {
          if (e.nativeEvent && typeof e.nativeEvent.stopImmediatePropagation === 'function') {
            e.nativeEvent.stopImmediatePropagation();
          }
        }
      }
    };
    return cbValue;
  }

  notifyChange(checked, e) {
    const cbValue = this.getEvent(checked, e);

    this._adapter.notifyChange(cbValue);
  }

  handleChange(e) {
    const disabled = this.getProp('disabled');

    if (disabled) {
      return;
    }

    if ((e === null || e === void 0 ? void 0 : e.type) === 'click') {
      this.clickState = true;
    }

    this._adapter.focusCheckboxEntity();

    const isInGroup = this._adapter.getIsInGroup();

    if (isInGroup) {
      const groupDisabled = this._adapter.getGroupDisabled();

      if (!groupDisabled) {
        this.handleChangeInGroup(e);
      }

      return;
    }

    const checked = this.getState('checked');
    const newChecked = !checked;

    if (this._isControlledComponent('checked')) {
      this.notifyChange(newChecked, e);
    } else {
      this.setChecked(newChecked);
      this.notifyChange(newChecked, e);
    }
  }

  handleChangeInGroup(e) {
    const {
      value
    } = this.getProps();

    const groupValue = this._adapter.getGroupValue();

    const checked = _includesInstanceProperty(groupValue).call(groupValue, value);

    const newChecked = !checked;
    const event = this.getEvent(newChecked, e);

    this._adapter.notifyChange(event);

    this._adapter.notifyGroupChange(event);
  }

  handleEnterPress(e) {
    if (isEnterPress(e)) {
      this.handleChange(e);
    }
  }

  setChecked(checked) {
    this._adapter.setNativeControlChecked(checked);
  } // eslint-disable-next-line @typescript-eslint/no-empty-function


  destroy() {}

}

export default CheckboxFoundation;