export const attentionSeekers = ['bounce', 'flash', 'headShake', 'heartBeat', 'jello', 'pulse', 'rubberBand', 'shake', 'swing', 'tada', 'wobble'];
export const bouncingEntrances = ['bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInRight', 'bounceInUp'];
export const bouncingExits = ['bounceOut', 'bounceOutDown', 'bounceOutLeft', 'bounceOutRight', 'bounceOutUp'];
export const fadingEntrances = ['fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight', 'fadeInRightBig', 'fadeInUp', 'fadeInUpBig'];
export const fadingExits = ['fadeOut', 'fadeOutDown', 'fadeOutDownBig', 'fadeOutLeft', 'fadeOutLeftBig', 'fadeOutRight', 'fadeOutRightBig', 'fadeOutUp', 'fadeOutUpBig'];
export const flippers = ['flip', 'flipInX', 'flipInY', 'flipOutX', 'flipOutY'];
export const lightspeed = ['lightSpeedIn', 'lightSpeedOut'];
export const rotatingEntrances = ['rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight'];
export const rotatingExits = ['rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight'];
export const slidingEntrances = ['slideInDown', 'slideInLeft', 'slideInRight', 'slideInUp'];
export const slidingExits = ['slideOutDown', 'slideOutLeft', 'slideOutRight', 'slideOutUp'];
export const specials = ['hinge', 'jackInTheBox', 'rollIn', 'rollOut'];
export const zoomingEntrances = ['zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp'];
export const zoomingExits = ['zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp'];