import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _setTimeout from "@babel/runtime-corejs3/core-js-stable/set-timeout";
import BaseFoundation from '../base/foundation';

class SpinFoundation extends BaseFoundation {
  constructor(adapter) {
    super(_Object$assign(_Object$assign({}, SpinFoundation.spinDefaultAdapter), adapter));
  }

  static get spinDefaultAdapter() {
    return {
      getProp: () => undefined,
      setLoading: val => undefined
    };
  }

  updateLoadingIfNeedDelay() {
    const {
      spinning: propsSpinning,
      delay: propsDelay
    } = this._adapter.getProps();

    const {
      delay
    } = this._adapter.getStates();

    if (delay) {
      const self = this;
      this._timer = _setTimeout(() => {
        self._adapter.setState({
          loading: propsSpinning,
          delay: 0
        });
      }, propsDelay);
    }
  }

  destroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

}

export default SpinFoundation;