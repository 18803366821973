import classNames from "classnames";
import { MainLayout } from "../../layout/MainLayout"
import * as styles from "./index.module.less";
import { Tutorials } from "./TutorialsData";
import { Tabs, TabPane, List } from "@douyinfe/semi-ui";
import { Accordion } from "./AccordionList";

export const HomePage = () => {
    return (
        <MainLayout>
            <div className={styles.homepage}>

                <div className={`container jump ${styles['jump']}`}>
                    <h1 className='box-title'>Decentralized Digital <br />Identities</h1>
                    <dl className={styles['jump-desc']}>
                        <dt>Use as your</dt>
                        <dd>Universal username across apps/dapps and webs/dwebs</dd>
                        <dd>One name for all of your crypto addresses</dd>
                        <dd>Website URL</dd>
                    </dl>
                </div>

                <div className={styles['bg-box']}>
                    <div className={classNames('container', styles.browsers)}>
                        <h2 className='box-title'>Supported Browsers</h2>
                        <div className={styles['browsers-row']}>
                            <a href="https://lake-reward-33f.notion.site/Surf-the-Decentralized-Web-15ca0c27c28c445eb04cc2cb6c15f30e" target='_blank' rel="noreferrer" className={styles.link}>
                                <i className={classNames(styles.icon, styles['icon-chrome'])} />Chrome
                            </a>
                            <a href="https://lake-reward-33f.notion.site/Surf-the-Decentralized-Web-15ca0c27c28c445eb04cc2cb6c15f30e" target='_blank' rel="noreferrer" className={styles.link}>
                                <i className={classNames(styles.icon, styles['icon-firefox'])} />Firefox
                            </a>
                            <a href="https://lake-reward-33f.notion.site/Surf-the-Decentralized-Web-15ca0c27c28c445eb04cc2cb6c15f30e" target='_blank' rel="noreferrer" className={styles.link}>
                                <i className={classNames(styles.icon, styles['icon-edge'])} />Edge
                            </a>
                        </div>
                        <div className='text-center'>
                            <a href="https://chrome.google.com/webstore/detail/ic-naming-extensions/oepbpdamkigabminkagecahfgdgbbodc" target='_blank' rel="noreferrer" className='ic-btn'>
                                <span className={classNames('ic-btn-inner', styles.extension)}>
                                    <i className={classNames(styles.icon, styles['icon-chrome'])} />Chrome Extension
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={styles.apps}>
                    <div className={`container`}>
                        <div className={styles['apps-header']}>
                            <div className={styles['apps-header-left']}>
                                <h2 className='box-title'>Applications</h2>
                                <span>Applications that support IC Naming</span>
                            </div>
                            <div className={styles['apps-header-btn-group']}>
                                <a href="https://lake-reward-33f.notion.site/Cooperative-Applications-7ae055c5799646e1a2cfe57391ddeb28" target='_blank' rel="noreferrer" className='ic-btn'>
                                    <span className='ic-btn-inner'>
                                        <i className={styles['icon-circle']}><b></b></i>
                                        View Applications
                                    </span>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQaAW1z7Z-X6lEp19po2EB1ASWFflZ62_OQ_MLmpCv3HqOPA/viewform?usp=sf_link" target='_blank' rel="noreferrer" className='ic-btn'>
                                    <span className='ic-btn-inner'>
                                        <i className={styles['icon-plus']}></i>
                                        Submit your Application
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className={styles['app-list']}>
                            <a href="https://mora.city/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl >
                                    <dt><i className={classNames(styles.icon, styles['icon-mora'])} />Mora</dt>
                                    <dd>Deliver the autonomy of thought and create your private space</dd>
                                </dl>
                            </a>
                            <a href="https://relationlabs.ai/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl >
                                    <dt><i className={classNames(styles.icon, styles['icon-relation'])} />Relation</dt>
                                    <dd>Encode Relationships on Web3</dd>
                                </dl>
                            </a>
                            <a href="https://tppkg-ziaaa-aaaal-qatrq-cai.raw.ic0.app/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl >
                                    <dt><i className={classNames(styles.icon, styles['icon-yumi'])} />Yumi</dt>
                                    <dd>Yumi is an ultra-fast, low-cost, and 100% decentralized NFT marketplace on ICP</dd>
                                </dl>
                            </a>
                            <a href="https://astrox.me/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl >
                                    <dt><i className={classNames(styles.icon, styles['icon-astrox'])} />AstroxMe</dt>
                                    <dd>Your self-custody passport to web3 apps, asset management in one place.</dd>
                                </dl>
                            </a>                            
                            <a href="https://www.stoicwallet.com/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl>
                                    <dt><i className={classNames(styles.icon, styles['icon-stoic'])} />Stoic Wallet</dt>
                                    <dd>Multi-purpose crypto wallet for the internet computer.</dd>
                                </dl>
                            </a>
                            <a href="https://icpbox.org/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl>
                                    <dt><i className={classNames(styles.icon, styles['icon-icpbox'])} />ICPBOX</dt>
                                    <dd>A defi wallet on the ICP chain that supports ICP, NFT, and token asset management and transfer.</dd>
                                </dl>
                            </a>
                            <a href="https://3pbcj-viaaa-aaaah-qaajq-cai.raw.ic0.app/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl>
                                    <dt><i className={classNames(styles.icon, styles['icon-icpswap'])} />ICP Swap</dt>
                                    <dd>The hub of future decentralized finance</dd>
                                </dl>
                            </a>
                            <a href="https://plugwallet.ooo/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl>
                                    <dt><i className={classNames(styles.icon, styles['icon-plug'])} />Plug</dt>
                                    <dd>Plug Wallet is a browser extension that allows you to access your ICP, Cycles and other tokens.</dd>
                                </dl>
                            </a>
                            <a href="https://wallet.infinityswap.one/" target="_blank" rel="noreferrer" className={styles['app-list-item']}>
                                <dl>
                                    <dt><i className={classNames(styles.icon, styles['icon-infinity'])} />Infinity Wallet</dt>
                                    <dd>Infinity Wallet to store and manage NFTs, Tokens, and connect to dApps on the Internet Computer.</dd>
                                </dl>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={classNames(styles['bg-box'],styles.faqs)} id='faq'>
                    <div className="container">
                        <Tabs className={styles['faq-tutorials']} tabPaneMotion={false}>
                            <TabPane tab="FAQ" itemKey="1">
                                <Accordion />
                            </TabPane>
                            <TabPane tab="Tutorials" itemKey="2">
                                <List
                                    dataSource={Tutorials}
                                    renderItem={({ title, link }) => <List.Item><a href={link} target="_blank" rel="noreferrer">{title}</a></List.Item>}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}