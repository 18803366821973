import _Map from "@babel/runtime-corejs3/core-js-stable/map";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
export default class Event {
  constructor() {
    this._eventMap = new _Map();
  }

  on(event, callback) {
    if (event && typeof callback === 'function') {
      this._eventMap.has(event) || this._eventMap.set(event, []);

      this._eventMap.get(event).push(callback);
    }

    return this;
  }

  once(event, callback) {
    var _this = this;

    if (event && typeof callback === 'function') {
      const fn = function () {
        callback(...arguments);

        _this.off(event, fn);
      };

      this.on(event, fn);
    }
  }

  off(event, callback) {
    if (event) {
      if (typeof callback === 'function') {
        const callbacks = this._eventMap.get(event);

        if (_Array$isArray(callbacks) && callbacks.length) {
          let index = -1; // eslint-disable-next-line max-depth

          while ((index = _findIndexInstanceProperty(callbacks).call(callbacks, cb => cb === callback)) > -1) {
            _spliceInstanceProperty(callbacks).call(callbacks, index, 1);
          }
        } // eslint-disable-next-line eqeqeq

      } else if (callback == null) {
        this._eventMap.delete(event);
      }
    }

    return this;
  }

  emit(event) {
    var _context;

    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    if (!this._eventMap.has(event)) {
      return false;
    }

    _forEachInstanceProperty(_context = this._eventMap.get(event)).call(_context, callback => callback(...args));

    return true;
  }

}