import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
export default function getDataAttr(props) {
  var _context;

  return _reduceInstanceProperty(_context = _Object$keys(props)).call(_context, (prev, key) => {
    if (key.substr(0, 5) === 'aria-' || key.substr(0, 5) === 'data-' || key === 'role') {
      prev[key] = props[key];
    }

    return prev;
  }, {});
}