import { PureComponent } from "react"
import styles from  './index.module.less';
import { Collapse, Table } from "@douyinfe/semi-ui"

const columns = [
    {
        title: 'Name Length',
        dataIndex: 'name',
    },
    {
        title: 'Register Price/Year',
        dataIndex: 'price',
    },
    {
        title: 'Renewal Price/Year',
        dataIndex: 'year',
    },
]
const data = [
    {
        key: '1',
        name: '>=7',
        price: '2.00 T Cycles',
        year: '2.00 T Cycles',
    },
    {
        key: '2',
        name: '6',
        price: '2.20 T Cycles',
        year: '2.20 T Cycles',
    },
    {
        key: '3',
        name: '5',
        price: '2.42 T Cycles',
        year: '2.42 T Cycles',
    },
    {
        key: '4',
        name: '4',
        price: '2.66 T Cycles',
        year: '2.66 T Cycles',
    },
    {
        key: '5',
        name: '3',
        price: '2.92 T Cycles',
        year: '2.92 T Cycles',
    },
    {
        key: '6',
        name: '2',
        price: '3.22 T Cycles (Not open yet)',
        year: '3.22 T Cycles',
    },
    {
        key: '7',
        name: '1',
        price: '3.54 T Cycles (Not open yet)',
        year: '3.54 T Cycles',
    }
]

export const QaData = [
    {
        title: "What's IC Naming?",
        paragraph: "IC Naming is a decentralized name service on IC."
    },
    {
        title: "What are IC Naming's name registration rules?",
        paragraph: "Contains letters (lowercase a-z), numbers (0-9), and hyphens ('-'), with a maximum length of 63 characters"
    },
    {
        title:"The domain name I purchased is .ic, why is it .ic.bike when accessed by the browser?",
        paragraph: <p>IC Naming provides .ic domain name service. When you purchase a .ic domain name, it means you have registered the .ic domain name. Because the current web3 domain name service has not been widely supported in browsers, web pages cannot be accessed directly using .ic domain names, so we provide two ways to access web pages.<br />1. Browser extensions. <br />2. ic.bike domain name.<br />.bike is the Internet domain name we purchased. When using .ic.bike, the access request will be parsed to the resolution record of the .ic domain name through the code, thereby achieving access to the web page.</p>
    },
    {
        title:"Why choose bike as the suffix?",
        paragraph:" .bike is a common Internet suffix, similar to .com. We use the .bike suffix because it is not convenient to go from web2 to web3. For example, the browser does not directly support web3 domain names. Taking transportation as an analogy, we are still in the bicycle stage, so we chose .bike. When web3 domain names become more and more widely used, we believe that browsers will also support direct access to web3 domain names."
    },
    {
        title: "How to purchase names?",
        paragraph: "You can purchase any names by entering the desirable names in our search bar or buying our blind box for more discount, you should link your wallet first to proceed the purchase."
    },
    {
        title: "How many wallet does ICNaming support?",
        paragraph: "We support Stoic wallet, ICPBox, Astrox etc."
    },
    {
        title: "What kind of payment do you support?",
        paragraph: "Currently, we support DICP"
    },
    {
        title: "What is DICP?",
        paragraph: "DICP is an ICP packaged according to DFT token standard."
    },
    {
        title: "how to determine the number of DICPs required for blind box payment?",
        paragraph: <>The price of the blind box is $3 each.<br />The number of DICPs paid will be adjusted according to the real-time price of ICP.</>
    },
    {
        title: "What is the renewal fee for name?",
        paragraph: <Table columns={columns} dataSource={data} pagination={false} />
    },
    {
        title: "How to pay cycles?",
        paragraph: "What you will actually have to pay is ICP.This will be converted at the time of order creation using the latest cycles/ICP rate from NNS."
    },
    {
        title: "Once I own a name, can I create my own subdomains?",
        paragraph: "Yes. You can create whatever subdomains you wish and assign ownership of them to other people if you desire. You can even set up your own registrar for your domain."
    },
    {
        title: "Where is the source code of IC Naming?",
        paragraph: <>You can find out the source code of IC Naming from <a style={{color:'#0044D4'}} target='_blank' rel='noreferrer' href='https://github.com/IC-Naming/icnaming'>https://github.com/IC-Naming/icnaming</a>, including smart contract in canister, browser extensions and etc.</>

    },
    {
        title: "What will happen to my old '.icp' name once the new version launched?",
        paragraph: "Your '.ICP' name will still be reserved, but once the one year service expires, the service will stop And cannot be renew. In most cases, users who held the ICP name will automatically receive the airdrop of '.ic' name for one year service. The IC name will be consistent with the ICP name. "
    }
]

export class Accordion extends PureComponent {
    render() {
        return (
            <Collapse className={styles.accordion}>
                {
                    QaData.map(({ title, paragraph }, idx) =>
                        <Collapse.Panel header={title} itemKey={idx.toString()} key={idx} >
                            {paragraph}
                        </Collapse.Panel>
                    )
                }
            </Collapse>
        )
    }
}
