.accordion{
  :global{
    .semi-collapse-item{
      margin:0 0 1rem;
      border: none;
    }
    .semi-collapse-header{
      border:none;
      margin: 0;
      background-color: #fff;
      box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
      font-family: HKGroteskBold;
      min-height: 4rem;
      font-size: 1.2rem;
      padding: 0.8rem 1rem;
      line-height: 1.3;
    }
    .semi-collapse-header[aria-expanded="false"]{
      border-radius: 12px;
    }
    .semi-collapse-header[aria-expanded="true"]{
      border-radius: 12px 12px 0 0;
    }
    .semi-collapse-content{
      background: #fff;
      border-radius: 0 0 12px 12px;
      padding: 1rem;
      font-family: HKGroteskRegular;
      font-size: 12px;
      line-height: 1.4;
    }
  }
}

@media screen and(min-width:768px) {
  .accordion{
    :global{
      .semi-collapse-header{
        padding: 0.8rem 2rem;
      }
      .semi-collapse-content{
        padding:0 2rem 2rem;
        font-size: 20px;
      }
    }
  }
}