import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";

/* istanbul ignore next */
class Store {
  constructor(initialState) {
    this._state = _Object$assign({}, initialState);
    this._listeners = [];
  }

  subscribe(listener) {
    this._listeners.push(listener);

    const unsubscribe = () => {
      var _context;

      const index = _indexOfInstanceProperty(_context = this._listeners).call(_context, listener);

      if (index > -1) {
        var _context2;

        _spliceInstanceProperty(_context2 = this._listeners).call(_context2, index, 1);
      }
    };

    return unsubscribe;
  }

  setState(state) {
    _Object$assign(this._state, _Object$assign({}, state));

    for (const listener of this._listeners) {
      if (typeof listener === 'function') {
        listener(this._state);
      }
    }
  }

  getState() {
    return this._state;
  }

}

export default Store;