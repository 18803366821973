import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import BaseFoundation from '../base/foundation';
import { handlePrevent, isPrintableCharacter, findIndexByCharacter, getAncestorNodeByRole, getMenuButton, setFocusToFirstItem, setFocusToItem, setFocusToNextMenuitem, setFocusToPreviousMenuItem } from '../utils/a11y';
export default class DropdownMenuFoundation extends BaseFoundation {
  constructor() {
    super(...arguments);
    this.menuItemNodes = null;
    this.firstChars = [];
  } // if trigger is click, auto focus to the first menu item


  autoFocus(ulElement) {
    const trigger = this._adapter.getContext('trigger');

    if (trigger === 'click') {
      var _context;

      // find all non-disabled li under this menu and set focus to the first menu
      this.menuItemNodes = _filterInstanceProperty(_context = [...ulElement.getElementsByTagName('li')]).call(_context, item => item.ariaDisabled !== "true");
      setFocusToFirstItem(this.menuItemNodes);
    }
  }

  handleEscape(menu) {
    const trigger = this._adapter.getContext('trigger');

    if (trigger === 'custom') {
      const menuButton = menu && getMenuButton(document.querySelectorAll("[data-popupid]"), menu.id);
      menuButton.focus();
    }
  }

  setFocusByFirstCharacter(curItem, char) {
    const index = findIndexByCharacter(this.menuItemNodes, curItem, this.firstChars, char);

    if (index >= 0) {
      setFocusToItem(this.menuItemNodes, this.menuItemNodes[index]);
    }
  }

  onMenuKeydown(event) {
    var _context5;

    const menu = getAncestorNodeByRole(event.target, 'tooltip');

    if (!this.menuItemNodes) {
      var _context2;

      this.menuItemNodes = _filterInstanceProperty(_context2 = [...event.target.parentNode.getElementsByTagName('li')]).call(_context2, item => item.ariaDisabled !== "true");
    }

    if (this.firstChars.length === 0) {
      var _context3;

      _forEachInstanceProperty(_context3 = this.menuItemNodes).call(_context3, item => {
        var _context4;

        this.firstChars.push(_trimInstanceProperty(_context4 = item.textContent).call(_context4)[0].toLowerCase());
      });
    } // get the currently focused menu item


    const curItem = _findInstanceProperty(_context5 = this.menuItemNodes).call(_context5, item => item.tabIndex === 0);

    switch (event.key) {
      case ' ':
      case 'Enter':
        event.target.click();
        handlePrevent(event);
        break;

      case 'Escape':
        this.handleEscape(menu);
        break;

      case 'ArrowUp':
        setFocusToPreviousMenuItem(this.menuItemNodes, curItem);
        handlePrevent(event);
        break;

      case 'ArrowDown':
        setFocusToNextMenuitem(this.menuItemNodes, curItem);
        handlePrevent(event);
        break;

      default:
        if (isPrintableCharacter(event.key)) {
          this.setFocusByFirstCharacter(curItem, event.key);
          handlePrevent(event);
        }

        break;
    }
  }

}