import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  TABS: "".concat(BASE_CLASS_PREFIX, "-tabs"),
  TABS_BAR: "".concat(BASE_CLASS_PREFIX, "-tabs-bar"),
  TABS_BAR_LINE: "".concat(BASE_CLASS_PREFIX, "-tabs-bar-line"),
  TABS_BAR_CARD: "".concat(BASE_CLASS_PREFIX, "-tabs-bar-card"),
  TABS_BAR_BUTTON: "".concat(BASE_CLASS_PREFIX, "-tabs-bar-button"),
  TABS_BAR_EXTRA: "".concat(BASE_CLASS_PREFIX, "-tabs-bar-extra"),
  TABS_TAB: "".concat(BASE_CLASS_PREFIX, "-tabs-tab"),
  TABS_TAB_ACTIVE: "".concat(BASE_CLASS_PREFIX, "-tabs-tab-active"),
  TABS_TAB_DISABLED: "".concat(BASE_CLASS_PREFIX, "-tabs-tab-disabled"),
  TABS_CONTENT: "".concat(BASE_CLASS_PREFIX, "-tabs-content"),
  TABS_CONTENT_ANIMATED: "".concat(BASE_CLASS_PREFIX, "-tabs-content-animated"),
  TABS_CONTENT_NO_ANIMATED: "".concat(BASE_CLASS_PREFIX, "-tabs-content-no-animated"),
  TABS_PANE: "".concat(BASE_CLASS_PREFIX, "-tabs-pane"),
  TABS_PANE_INACTIVE: "".concat(BASE_CLASS_PREFIX, "-tabs-pane-inactive"),
  TABS_PANE_ACTIVE: "".concat(BASE_CLASS_PREFIX, "-tabs-pane-active"),
  TABS_PANE_MOTION_OVERLAY: "".concat(BASE_CLASS_PREFIX, "-tabs-pane-motion-overlay"),
  TABS_PANE_ANIMATING: "".concat(BASE_CLASS_PREFIX, "-tabs-pane-animating")
};
const numbers = {
  DEFAULT_ACTIVE_KEY: 1
};
const strings = {
  TYPE_MAP: ['line', 'card', 'button'],
  SIZE: ['small', 'medium', 'large'],
  POSITION_MAP: ['top', 'left']
};
export { cssClasses, numbers, strings };