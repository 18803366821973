.header{
  position:sticky;
  top: 0;
  height: 3rem;
  z-index: 9;
  background: #fff;
  .container{
    max-width: 1260px;
    margin: auto;
    height: 100%;
  }
}

.navbar{
  height: 100%;
  color: #fff;
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  .navbar-toggler{
    font-size: 1rem;
    color: #00CFBB;
    outline: none;
    box-shadow: none;
    padding: 0;
    border-radius: 8px;
    border: 1px solid #00CFBB;
    width: 32px;
    height: 32px;
  }
}

.header-logo{
  background: url('./image/logo.svg') no-repeat center;
  background-size: contain;
  font-size: 0;
  height: 2rem;
  width: 100%;
  margin-left: -2rem;
}

.go-dapp{
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  border-radius: 40px;
  font-weight: bold;
  color: #16042F;
  font-size: 14px;
  width: auto;
  height: auto;
  padding: 1px;
  font-weight: 400;
  color: #0044D4;
  box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
  transition: all .3s;
}
.go-dapp:active{
  background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
}
.go-dapp-inner{
  background-color: #FCFBFE;
  border-radius: 40px;
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: block;padding: 8px 0.75rem;
}


.btn-menu,.btn-close{
  border: none;
  padding: 0;
  background: none;
  position:relative;
  z-index: 99;
  background: url(./image/icon-menu.svg) no-repeat center;
  width: 2rem;
  height: 2rem;
}
.btn-close{
  background: url(./image/icon-close.svg) no-repeat center;
}

.mobile-menu{
  transform: translateX(-105%);
  position: fixed;
  width: 80vw;
  height: 100vh;
  z-index: 999;
  left: 0;
  top: 0;
  transition: .5s;
  .mobile-menu-main{
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  &.show{
    transform: translateX(0);
  }
}

.mobile-menu-main-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 0;
  .header-logo{
    width: 8rem;
    margin: 0;
  }
}

.navbar-collapse{
  display: none;
  .nav-item-link{
    margin-right: 2rem;
    transition: all .3s;
    background-clip: text;
    &:hover{
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
      -webkit-background-clip: text;
    }
  }
}
.mobile-menu-main-body{
  .navbar-collapse{
    display: flex;
    flex-direction: column;
    padding: 3rem 0 0 10px;
    li{
      margin-bottom: .5rem;
      padding: 1px 0 1px 1px;
      .nav-item-link{
       
        // background: #fff;
        color: #16042F;
        height: 3.75rem;
        display: block;
        margin: 0;
        line-height: 3.75rem;
        padding-left: 2rem;
        font-size: 1rem;
        font-weight: 700;
        transition: none;
        &:hover{
          border-radius: 5rem 0px 0px 8px;
          background: linear-gradient(270deg, rgba(115, 0, 230, 0) 0%, rgba(115, 0, 230, 0.1) 100%);
          color: #7300E6;
          background-clip:initial;
          -webkit-text-fill-color:#7300E6;
        }
      }
    }
    li:last-child{
      text-align: center;
      padding-top: 2rem;
      border: none;
      background: none;
    }
    .go-dapp{
      margin:0 auto;
     height: 48px;
     line-height: 47px;
      padding:0;
      color: #820FF0;
      box-shadow: 4px 4px 12px 0px #C7BDDB, -4px -4px 12px 0px #FFFFFF;
      .go-dapp-inner{padding: 0 2rem }
    }
  }
}

@media (min-width: 576px) {
  .navbar {
    justify-content: space-between;
    padding: 0 1rem;
  }
  .header{
    height: 80px;
    background: none;
    transition: all .3s;
    &.has-bg{
      background: rgba(255,255,255,0.6);
      backdrop-filter: blur(8px);
    }
  }
  .header-logo{
    background: url('./image/logo.svg') no-repeat left center;
    background-size: contain;
    width: 180px;
    height: 40%;
    margin: 0;
  }
  .navbar-collapse{
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    background-color: transparent;
    padding: 0;
    transform: none;
    position: static;
  }
  .btn-menu,.mobile-menu{
    display: none;
  }
}
@media screen and (min-width: 789px){
  .navbar-collapse .nav-item-link{
      margin-right: 4rem;
  }
  .go-dapp:hover{
    background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
  }
}