import { BASE_CLASS_PREFIX } from '../base/constants';
const checkboxClasses = {
  PREFIX: "".concat(BASE_CLASS_PREFIX, "-checkbox"),
  INNER: "".concat(BASE_CLASS_PREFIX, "-checkbox-inner"),
  TEXT: "".concat(BASE_CLASS_PREFIX, "-checkbox-text"),
  INPUT: "".concat(BASE_CLASS_PREFIX, "-checkbox-input"),
  CHECKED: "".concat(BASE_CLASS_PREFIX, "-checkbox-checked"),
  DISABLED: "".concat(BASE_CLASS_PREFIX, "-checkbox-disabled"),
  BUTTON: "".concat(BASE_CLASS_PREFIX, "-checkbox-button"),
  WRAPPER: ''
};
const checkboxGroupClasses = {
  PREFIX: "".concat(BASE_CLASS_PREFIX, "-checkboxGroup"),
  INNER: "".concat(BASE_CLASS_PREFIX, "-checkboxGroup-inner"),
  TEXT: "".concat(BASE_CLASS_PREFIX, "-checkboxGroup-text"),
  INPUT: "".concat(BASE_CLASS_PREFIX, "-checkboxGroup-input"),
  CHECKED: "".concat(BASE_CLASS_PREFIX, "-checkboxGroup-checked"),
  DISABLED: "".concat(BASE_CLASS_PREFIX, "-checkboxGroup-disabled")
};
const strings = {
  DIRECTION_SET: ['horizontal', 'vertical'],
  TYPE_DEFAULT: 'default',
  TYPE_CARD: 'card',
  TYPE_PURECARD: 'pureCard',
  DEFAULT_DIRECTION: 'vertical'
};
const numbers = {};
export { checkboxClasses, checkboxGroupClasses, strings, numbers };