import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
export default function invokeFns(fns) {
  let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  if (_Array$isArray(fns) && fns.length) {
    _forEachInstanceProperty(fns).call(fns, fn => {
      if (typeof fn === 'function') {
        fn(...args);
      }
    });
  }
}