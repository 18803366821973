import * as styles from './index.module.less'
import { useEffect, useState } from 'react';
import classNames from 'classnames';

export const Header = () => {
  const [hasBg, setHasBg] = useState(false)
  const [show, setShow] = useState(false)

  const handleScroll = () => {
    let scrollTop = document.documentElement.scrollTop;
    scrollTop > 80 ? setHasBg(true) : setHasBg(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  const navList = () => {
    return (
      <ul className={styles['navbar-collapse']}>
        <li>
          <a className={styles["nav-item-link"]} href={`/#faq`} onClick={() => setShow(false)}>FAQ</a>
        </li>
        <li>
          <a className={styles["nav-item-link"]} target="_blank" rel="noreferrer" href='https://lake-reward-33f.notion.site/ICNaming-Wiki-b901af6b34c9434f9623377905a4ae78'>Wiki</a>
        </li>
        <li>
          <a className={styles["nav-item-link"]} target="_blank" rel="noreferrer" href='https://lake-reward-33f.notion.site/Technical-Reference-05edce2048a44471b038df838c3cd117'>Docs</a>
        </li>
        <li>
          <a className={classNames(styles['go-dapp'])} href="https://app.icnaming.com">
            <span className={styles['go-dapp-inner']}>Go to DAPP</span>
          </a>
        </li>
      </ul>
    )
  }

  return (
    <header className={classNames(styles.header, { [styles['has-bg']]: hasBg })}>
      <div className={styles.container}>
        <div className={styles.navbar}>
          <button className={styles['btn-menu']} onClick={() => setShow(true)} />
          <a href='/' className={`${styles['header-logo']}`}>logo</a>
          {navList()}
        </div>
      </div>
      <div className={classNames('mask', { 'show': show })} onClick={() => setShow(false)}/>
      <div className={classNames(styles['mobile-menu'], { [styles.show]: show })}>
        <div className={styles['mobile-menu-main']}>
          <div className={styles['mobile-menu-main-head']}>
            <a href='/' className={`${styles['header-logo']}`}>logo</a>
            <button className={styles['btn-close']} onClick={() => setShow(false)} />
          </div>
          <div className={styles['mobile-menu-main-body']}>
            {navList()}
          </div>
        </div>
      </div>
    </header>
  )
}