import './index.less';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './Home';
import { PressKit } from './PressKit';


const PageFrame = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<>Loading...</>}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/press-kit" element={<PressKit />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
export default PageFrame;