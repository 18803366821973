const VirtualRow = _ref => {
  let {
    index,
    data,
    style
  } = _ref;
  const {
    visibleOptions
  } = data;
  const option = visibleOptions[index];
  return data.renderOption(option, index, style);
};

export default VirtualRow;