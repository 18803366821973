import { PureComponent } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";


export class MainLayout extends PureComponent {
    render() {
        return (
            <div className="pageWrap">
                <Header />
                {this.props.children}
                <Footer />
            </div>
        )
    }
}

