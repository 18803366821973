import _isFunction from "lodash/isFunction";
import _isString from "lodash/isString";
import _isNumber from "lodash/isNumber";
import _set from "lodash/set";
import _noop from "lodash/noop";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import BaseFoundation from '../base/foundation';
import { strings } from './constants';
import { ENTER_KEY } from './../utils/keyCode';

class InputFoundation extends BaseFoundation {
  constructor(adapter) {
    super(_Object$assign(_Object$assign({}, InputFoundation.inputDefaultAdapter), adapter));
  }

  static get inputDefaultAdapter() {
    return {
      notifyChange: _noop,
      setValue: _noop // toggleAllowClear: noop,

    };
  }

  init() {
    this._setInitValue();
  }

  destroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  } // eslint-disable-next-line


  setDisable() {}

  _setInitValue() {
    const {
      defaultValue,
      value
    } = this.getProps();
    let v = defaultValue;

    if (this._isControlledComponent()) {
      v = value;
    }

    this._adapter.setValue(v); // this.checkAllowClear(v);

  }

  setValue(value) {
    this._adapter.setValue(value);
  }

  handleChange(value, e) {
    const {
      maxLength,
      minLength,
      getValueLength
    } = this._adapter.getProps();

    let nextValue = value;

    if (maxLength && _isFunction(getValueLength)) {
      nextValue = this.handleVisibleMaxLength(value);
    }

    if (minLength && _isFunction(getValueLength)) {
      this.handleVisibleMinLength(nextValue);
    }

    if (this._isControlledComponent()) {
      /**
       * If it is a controlled component, directly notify the caller of the modified value.
       * Truncate the input value from the input box if the input value exceeds the maximum length limit.
       *  Even in controlled components, characters that exceed the length limit cannot be entered through the input box.
       */
      this._adapter.notifyChange(nextValue, e);
    } else {
      this._adapter.setValue(nextValue);

      this._adapter.notifyChange(nextValue, e); // this.checkAllowClear(value);

    }
  }
  /**
   * Modify minLength to trigger browser check for minimum length
   * Controlled mode is not checked
   * @param {String} value
   */


  handleVisibleMinLength(value) {
    const {
      minLength,
      getValueLength
    } = this._adapter.getProps();

    const {
      minLength: stateMinLength
    } = this._adapter.getStates();

    if (_isNumber(minLength) && minLength >= 0 && _isFunction(getValueLength) && _isString(value)) {
      const valueLength = getValueLength(value);

      if (valueLength < minLength) {
        const newMinLength = value.length + (minLength - valueLength);
        newMinLength !== stateMinLength && this._adapter.setMinLength(newMinLength);
      } else {
        stateMinLength !== minLength && this._adapter.setMinLength(minLength);
      }
    }
  }
  /**
   * Handle input emoji characters beyond maxLength
   * Controlled mode is not checked
   * @param {String} value
   */


  handleVisibleMaxLength(value) {
    const {
      maxLength,
      getValueLength
    } = this._adapter.getProps();

    if (_isNumber(maxLength) && maxLength >= 0 && _isFunction(getValueLength) && _isString(value)) {
      const valueLength = getValueLength(value);

      if (valueLength > maxLength) {
        // eslint-disable-next-line max-len
        console.warn('[Semi Input] The input character is truncated because the input length exceeds the maximum length limit');
        const truncatedValue = this.handleTruncateValue(value, maxLength);
        return truncatedValue;
      } else {
        return value;
      }
    }
  }
  /**
   * Truncate input values based on maximum length
   * @param {String} value
   * @param {Number} maxLength
   * @returns {String}
   */


  handleTruncateValue(value, maxLength) {
    const {
      getValueLength
    } = this._adapter.getProps();

    if (_isFunction(getValueLength)) {
      let truncatedValue = '';

      for (let i = 1, len = value.length; i <= len; i++) {
        const currentValue = _sliceInstanceProperty(value).call(value, 0, i);

        if (getValueLength(currentValue) > maxLength) {
          return truncatedValue;
        } else {
          truncatedValue = currentValue;
        }
      }

      return truncatedValue;
    } else {
      return _sliceInstanceProperty(value).call(value, 0, maxLength);
    }
  }

  handleClear(e) {
    let eventObj = e;
    const value = ''; // let input = this._adapter.getInput();

    if (this._isControlledComponent('value')) {
      this._adapter.setState({
        isFocus: false
      });
    } else {
      this._adapter.setState({
        value: '',
        isFocus: false
      });
    }

    if (!eventObj || typeof eventObj !== 'object') {
      eventObj = {};
    }

    _set(eventObj, strings.CLEARBTN_CLICKED_EVENT_FLAG, true); // this is useful for DateInput


    this._adapter.notifyChange(value, eventObj);

    this._adapter.notifyClear(eventObj);

    if (eventObj) {
      // When input is in popover and popover needs to judge clickOutSide, such as TreeSelect
      // If the click event bubbles up, it will mistakenly trigger clickOutSide's judgment.
      // At the same time, because the clear icon is not in the dom tree after clicking, and clickOutSide uses dom.contain (e.target), it will be considered as clicking on the outside, which will cause the floating layer to fold
      // So we need to stop the incident from bubbling up
      this.stopPropagation(eventObj);
    }
  }
  /**
   * trigger when click input wrapper
   * @param {Event} e
   */


  handleClick(e) {
    const {
      disabled
    } = this._adapter.getProps();

    const {
      isFocus
    } = this._adapter.getStates();

    if (disabled || isFocus) {
      return;
    } // do not handle bubbling up events


    if (this._adapter.isEventTarget(e)) {
      this._adapter.toggleFocusing(true);
    }
  }

  handleModeChange(mode) {
    if (mode === 'password') {
      this._adapter.setEyeClosed(true);
    } else {
      this._adapter.setEyeClosed(false);
    }
  }

  handleClickEye(e) {
    const eyeClosed = this._adapter.getState('eyeClosed');

    this._adapter.toggleFocusing(true);

    this._adapter.setEyeClosed(!eyeClosed);
  }

  handleInputType(type) {
    const mode = this._adapter.getProp('mode');

    const eyeClosed = this._adapter.getState('eyeClosed');

    if (mode === 'password') {
      return eyeClosed ? 'password' : 'text';
    }

    return type;
  }

  handleMouseDown(e) {
    e.preventDefault();
  }

  handleMouseUp(e) {
    e.preventDefault();
  }

  handleBlur(e) {
    const {
      value
    } = this.getStates();

    this._adapter.toggleFocusing(false);

    this._adapter.notifyBlur(value, e);
  }

  handleFocus(e) {
    const {
      value
    } = this.getStates();

    this._adapter.toggleFocusing(true); // this.checkAllowClear(this.getState('value'), true);


    this._adapter.notifyFocus(value, e);
  }

  handleInput(e) {
    this._adapter.notifyInput(e);
  }

  handleKeyDown(e) {
    this._adapter.notifyKeyDown(e);
  }

  handleKeyUp(e) {
    this._adapter.notifyKeyUp(e);
  }

  handleKeyPress(e) {
    this._adapter.notifyKeyPress(e);

    if (e.key === ENTER_KEY) {
      this._adapter.notifyEnterPress(e);
    }
  }

  setPaddingLeft(paddingLeft) {
    this._adapter.setPaddingLeft(paddingLeft);
  }

  isAllowClear() {
    const {
      value,
      isFocus,
      isHovering
    } = this._adapter.getStates();

    const {
      showClear,
      disabled
    } = this._adapter.getProps();

    const allowClear = value && showClear && !disabled && (isFocus || isHovering);
    return allowClear;
  }

  handleClickPrefixOrSuffix(e) {
    const {
      disabled
    } = this._adapter.getProps();

    const {
      isFocus
    } = this._adapter.getStates();

    if (!disabled && !isFocus) {
      this._adapter.toggleFocusing(true);
    }
  }
  /**
   * Blocking mousedown events prevents input from losing focus
   * @param {Event} e
   */


  handlePreventMouseDown(e) {
    if (e && _isFunction(e.preventDefault)) {
      e.preventDefault();
    }
  }
  /**
   * A11y: simulate password button click
   */


  handleModeEnterPress(e) {
    var _context;

    // trigger by Enter or Space key
    if (_includesInstanceProperty(_context = ['Enter', ' ']).call(_context, e === null || e === void 0 ? void 0 : e.key)) {
      this.handlePreventMouseDown(e);
      this.handleClickEye(e);
    }
  }

}

export default InputFoundation;