.press-wrap{
    padding: 1rem 0;
   }
   .my-breadcrumb{
     display: flex;
     justify-content: flex-start;
     align-items: center;
     font-size: 1rem;
     line-height: 24px;
     margin-top: 10px;
     color: #16042F;
     a{
       color:#16042F;
     }
     span{
       background: url('./image/arrow_r@2x.png') no-repeat left center;
       background-size: auto 80%;
       padding-left: 18px;
       margin-left: .3rem;
     }
   }
   
   .press-content{
     padding-top: 50px;
     margin-bottom: 80px;
     h1{
       display: inline-block;
       font-family: HKGroteskBold;
       font-size: 40px;
       font-weight: bold;
       margin-bottom: 16px;
       background-clip: text;
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       background-image: linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
       color: #00CFBB;
     }
     h2{
       color: #585063;
       font-size: 20px;
       margin-bottom: 40px;
     }
     .img-logo {
       border-radius: 0.5rem;
       display: block;
       width: 100%;
       background-color: #F9F8FC;
       text-align: center;
       height: 315px;
       margin-bottom: 10px;
       .img-w{
         height: 250px;
         width: 100%;
         display: inline-flex;
         img{margin: auto;
         max-width: 80%;
       }
       }
       div:last-child {
         transition: all .3s;
         text-align-last: center;
         color: #16042F;
         width: 100%;
         height: 65px;
         padding: 20px 0;
         text-align: center;
         border-radius: 0 0 6px 6px;
         display: flex;
         align-items: center;
         justify-content: center;
         .icon-download{
           transition: all .3s;
           width: 24px;
           height: 24px;
           margin-left: 14px;
           color: #16042F;
         }
       }
       &:hover{
         box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
         div:last-child{
           background:linear-gradient(270deg, rgba(84, 30, 172, 1), rgba(148, 38, 207, 1), rgba(38, 207, 184, 1));
           color: #fff;
           .icon-download{
             color: #fff;
           }
         }
       }
     }
   }
   
   .row{
     display: flex;
     align-items: flex-start;
     justify-content: space-between;
     flex-direction: column;
   }
   .col-md-4{
     width: 100%;
   }
   @media screen and(min-width:769px){
     .col-md-4{
       width: 33.33%;
       padding: 0 1rem;
     }
     .row{
       display: flex;
       align-items: flex-start;
       justify-content: space-between;
       flex-direction: row;
     }
   }