import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import BaseFoundation from '../base/foundation';
export default class RadioGroupFoundation extends BaseFoundation {
  constructor(adapter) {
    super(_Object$assign({}, adapter));
  }

  init() {
    const displayValue = this._getDisplayValue();

    this._setValue(displayValue);
  }

  _getDisplayValue() {
    const {
      value,
      defaultValue
    } = this.getProps();
    let displayValue;

    if ('value' in this.getProps()) {
      displayValue = value;
    } else if ('defaultValue' in this.getProps()) {
      displayValue = defaultValue;
    }

    return displayValue;
  }

  handleChange(evt) {
    const mode = this.getProp('mode');
    const lastValue = this.getState('value');
    const {
      checked,
      value
    } = evt.target;

    const isControlledComponent = this._adapter.isInProps('value');

    const cbValue = _Object$assign(_Object$assign({}, evt), {
      target: _Object$assign(_Object$assign({}, evt.target), {
        value
      })
    });

    if (mode === 'advanced' && !checked) {
      cbValue.target.value = undefined;
    }

    if (!isControlledComponent) {
      if (mode === 'advanced' && !checked) {
        this._setValue(undefined);
      } else {
        this._setValue(value);
      }
    }

    if (mode === 'advanced' || lastValue !== value) {
      this._adapter.notifyChange(cbValue);
    }
  } // call when prop.value change


  handlePropValueChange(propValue) {
    this._setValue(propValue);
  }

  _setValue(value) {
    this._adapter.setValue(value);
  } // eslint-disable-next-line @typescript-eslint/no-empty-function


  destroy() {}

}