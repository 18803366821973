.homepage{height: 100%;}
.jump{
    background: url(./image/banner.webp) no-repeat;
    background-position: center bottom;
    background-size: contain;
    height: auto;
    padding-bottom: 25rem;
    padding-top: 3.75rem;
    padding-left: 1.875rem;
    h1{
        line-height: 1.875rem;
        margin-bottom: 10px;
        text-align: left;
        &::after{
            content: '';
            display: block;
            width: 125px;
            height: 2px;
            background: linear-gradient(270deg, rgba(34, 238, 249, 0) 0%, #820FF0 100%);
            margin-top: 0.675rem;
        }
    }
    .jump-desc{
        dt{
            
            font-weight: bold;
            color: #16042F;
            margin-bottom: 0.5rem;
            font-family: HKGroteskBold;
        }
        dd{
            font-size: 14px;
            font-weight: 400;
            color: #585063;
            margin-bottom: 8px;
            &::before{
                content: '';
                display: inline-block;
                margin-right: 4px;
                width: 2px;
                height: 10px;
                background: #820FF0;
            }
        }
    }
}

.bg-box{
    background: rgba(248, 246, 254, 1);
    padding: 2.5rem 0 3.75rem;

    .browsers-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .link{
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 180px;
            background: #F8F6FE;
            border-radius: 6px;
            border: 1px solid #EEEEEE;
            margin-bottom: 10px;
            font-weight: bold;
            color: #16042F;
            transition: all .3s;
            -webkit-tap-highlight-color: transparent;
            font-family: HKGroteskBold;
            &:active{
                box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
            }
            &:last-child{
                margin-bottom:2.5rem ;
            }
        }
        
    }
}

.browsers{
    .icon{
        display: block;
        width: 4.375rem;
        height: 4.375rem;
        margin-bottom: 12px;
        background: url(./image/css_sprites.png);
        background-repeat: no-repeat;
        background-size: 240px 182.5px;
        &.icon-chrome{background-position: -85px -5px;}
        &.icon-firefox{background-position: -5px -5px; height: 4.5rem;}
        &.icon-edge{background-position: -165px -5px;}
    }
    .extension{
        display: flex;
        align-items: center;
        .icon-chrome{
            width: 2.1875rem;
            height: 2.1875rem;
            background-size: 120px 91.25px;
            background-position: -42.5px -2.5px;
            margin-bottom: 0;
            margin-right: .5rem;
        }
    } 
}


.apps{
    padding: 5rem 0 2.5rem;
}
.apps-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.875rem;
}
.apps-header-left{
    margin-bottom: 1.875rem;
}
.apps-header-btn-group{
    display: flex;
    justify-content: space-between;
    width: 100%;
    &>a{
        height: 2.5rem;
        width: 48%;
        font-size: 0.75rem;
        font-weight: normal;
        white-space:nowrap;
        -webkit-tap-highlight-color: transparent;
    }
    :global{
        .ic-btn-inner{
            display: flex;
            align-items: center;
        }
    }
}

.icon-circle{
    position: relative;
    height: 12px;
    width:12px;
    display: block;
    margin-right: 0.5rem;
    &::before,&::after,b,b::after{
        content: '';
        box-sizing: border-box;
        width: 4.5px;
        height: 4.5px;
        border: 1px solid #16042F;
        display: block;
        position: absolute;
        border-radius: 50%;
    }
    &::after{
        right: 0;
    }
    b{
        bottom: 0;
        left: 0;
    }
    b::after{
        top: -1px;
        left: 6.5px;
    }
}

.icon-plus{
    position: relative;
    height: 14px;
    width:14px;
    display: block;
    margin-right: 0.5rem;
    border-radius: 4px;
    border: 1px solid #16042F;
    transform: rotate(45deg);
    &::before,&::after{
        content: '';
        display: block;
        position: absolute;
        height: 10px;
        width: 1px;
        background: #16042F;
        top: 1px;
        left: 5px;
        transform: rotate(45deg);
    }
    &::after{
        transform: rotate(135deg);
    }
}

.app-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .app-list-item{
        padding: 1rem;
        width: 48%;
        min-height: 7.8rem;
        background: #F9F8FC;
        margin-bottom: 1rem;
        border-radius: 0.75rem;
        color: #585063;
        font-size: 12px;
        transition: all .3s;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        &:active{
            background: #FCFBFE;
            box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
        }
        dt{
            margin-bottom: .5rem;
            font-weight: bold;
            font-size: 15px;
            font-family: HKGroteskBold;
        }
        .icon{
            width: 2.5rem;
            height: 2.5rem;
            display: block;
            background-image: url(./image/css_sprites.png);
            background-repeat: no-repeat;
            background-size: 240px 182.5px;
            margin-bottom: 0.75rem;
            &.icon-astrox{background-position: -5px -87.5px;}
            &.icon-stoic{background-position: -55px -137.5px;}
            &.icon-icpbox{background-position: -105px -87.5px;}
            &.icon-icpswap{background-position:-55px -87.5px;}
            &.icon-plug{background-position:-5px -137.5px;}
            &.icon-infinity{background-image: url(./image/infinity.svg);background-size: 80%; background-position: center !important;}

        }
        .icon-mora{background-image: url(./image/mora.png);background-size: 80% 80% !important; background-position: center;}
        .icon-relation{background-image: url(./image/relation.png);background-size: 80% 80% !important;background-position: center;}
        .icon-yumi{background-image: url(./image/yumi.png);background-size: 80% 80% !important;background-position: center;}
    }
}

.faq-tutorials{
    :global{
        .semi-tabs-bar-line.semi-tabs-bar-top{
            border:none;
            margin-bottom: 2.5rem;
            .semi-tabs-tab{
                font-size: 1.5rem;
                color: #585063;
                font-weight: 600;
                outline: none;
                &:hover{
                    border:none;
                }
                &.semi-tabs-tab-active{
                    border-bottom:2px solid #820FF0;
                    color: #16042F;
                }
            }
        }
       
        .semi-tabs-pane{
            overflow: visible;
        }
        .semi-list-item{
            border:none;
            border-radius: 12px;
            margin-bottom: 1rem;
            background-color: #fff;
            box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
            font-family: HKGroteskBold;
            height: 4rem;
            padding: 0.8rem 1rem;
            a{
                display: block;
                width: 100%;
                transition: color .3s;
                font-size: 1.2rem;
                &:hover{
                    color: #820FF0;
                }
            }
        }
    }
}

@media screen and (min-width:576px) {
    .jump{
        background-position:center right;
        background-size: contain;
        height: 800px;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1{
            font-size: 60px;
            line-height: 1.875rem*2;
            margin-bottom: 20px;
            &::after{
                content: '';
                display: block;
                width: 250px;
                height: 4px;
                background: linear-gradient(270deg, rgba(34, 238, 249, 0) 0%, #820FF0 100%);
                margin-top: 0.675rem;
            }
        }
        .jump-desc{
            dt{
                font-size: 20px;
                margin-bottom: 1rem;
            }
            dd{
                font-size: 20px;
                margin-bottom: 1rem;
                &::before{
                    margin-right: 8px;
                    width: 4px;
                    height: 16px;
                }
            }
        }
    }

    .app-list .app-list-item:hover{
        background: #FCFBFE;
        box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
    }
}

@media screen and (min-width:769px) {
    .bg-box{
        padding: 80px 0 140px;
        .browsers-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .link{
                width: 300px;
                height: 320px;
                border-radius: 12px;
                border: none;
                font-size: 20px;
                margin-bottom: 80px;
                &:hover{
                    box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
                }
            }
        }
    }

    .browsers{
        .icon{
            display: block;
            width:140px;
            height:140px;
            margin-bottom: 30px;
            background-size: 480px 365px;
            &.icon-chrome{background-position: -170px -10px;}
            &.icon-firefox{background-position: -10px -10px;height: 145px;}
            &.icon-edge{background-position: -330px -10px;}
        }
        .extension .icon-chrome{
            width: 2.1875rem;
            height: 2.1875rem;
            background-size: 120px 91.25px;
            background-position: -42.5px -2.5px;
        }
    }

    .apps-header{
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1.875rem;
    }


    .apps-header-btn-group{
        justify-content: space-between;
        width: auto;
        &>a{
            height: 60px;
            width: auto;
            font-size: 20px;
            font-weight: 400;
            &:last-child{
                margin-left: 1.5rem;
            }
            span{
                padding: 0 1rem;
            }
        }
    }

    .app-list{
        .app-list-item{
            padding:1.5rem 1.75rem;
            min-height: 240px;
            width: 32%;
            margin-bottom: 1.8rem;
            font-size: 20px;
            line-height: 140%;
            dt{
                font-size: 1.875rem;
            }
            .icon{
                width: 5rem;
                height: 5rem;
                background-size: auto;
                &.icon-astrox{background-position: -10px -175px;}
                &.icon-stoic{background-position: -110px -275px;}
                &.icon-icpbox{background-position: -210px -175px;}
                &.icon-icpswap{background-position: -110px -175px;}
                &.icon-plug{background-position: -10px -275px;}
                &.icon-infinity{background-position: -310px -175px;}
            }
        }
    }

    .icon-plus{
        height: 20px;
        width:20px;
        border: 2px solid #16042F;
        &::before,&::after{
            width: 2px;
            top: 3px;
            left: 7px;
        }
    }
    .icon-circle{
        height: 20px;
        width:20px;
        &::before,&::after,b,b::after{
            content: '';
            box-sizing: border-box;
            width: 9px;
            height: 9px;
            border: 2px solid #16042F;
        }
        b::after{
            top: -2px;
            left: 9px;
        }
    }

    .faq-tutorials{
        :global{
            .semi-tabs-bar-line.semi-tabs-bar-top .semi-tabs-tab{
                font-size: 2.5rem;
                padding: 1rem 0 1.5rem;
            }
            .semi-list-item{
                padding: 0.8rem 2rem;
            }
        }
    }
}


@media screen and (min-width:992px){
    .apps-header-btn-group{
        &>a{
            &:last-child{
                margin-left: 3.75rem;
            }
        }
    }
} 

.faqs{
    padding-bottom: 0;
}