import { Link } from "react-router-dom";
import { MainLayout } from "../../layout/MainLayout"
import * as styles from "./index.module.less";
import Download  from 'jsx:./image/download.svg';

const logo1 = new URL("./image/press/logo-512.png",import.meta.url) + '',
logo2 = new URL("./image/press/logo_b.png",import.meta.url) + '',
logo3 = new URL("./image/press/ICNAMING2_b_h.png",import.meta.url) + '',
press = new URL("./image/press/press.zip",import.meta.url) + '';
export const PressKit = () => {
    return (
        <MainLayout>
            <div className={styles['press-wrap']}>
                <div className="container">
                    <div className={styles['my-breadcrumb']}>
                        <Link to="/">Home</Link>
                        <span>Press kit</span>
                    </div>
                    <div className={styles['press-content']}>
                        <h1>IC NAMING</h1>
                        <h2>The ICNAMING logo</h2>
                        <div className={styles.row}>
                            <div className={styles['col-md-4']}>
                                <a className={`${styles['img-logo']} img-fluid`} href={press}>
                                    <div className={styles['img-w']}><img alt="ICNAMING Logo" style={{ height: '60%' }} src={logo1} /></div>
                                    <div>
                                        <strong>ICNAMING LOGO</strong> <i className={styles["icon-download"]}><Download /></i>
                                    </div>
                                </a>
                            </div>
                            <div className={styles['col-md-4']}>
                                <a className={`${styles['img-logo']} img-fluid`} href={press}>
                                    <div className={styles['img-w']}><img alt="ICNAMING Logo" style={{ height: '60%' }} src={logo2} /></div>
                                    <div>
                                        <strong>ICNAMING LOGO</strong> <i className={styles["icon-download"]}><Download /></i>
                                    </div>
                                </a>
                            </div>
                            <div className={styles['col-md-4']}>
                                <a className={`${styles['img-logo']} img-fluid`} href={press}>
                                    <div className={styles['img-w']}><img alt="ICNAMING Logo" src={logo3} /></div>
                                    <div>
                                        <strong>ICNAMING LOGO</strong> <i className={styles["icon-download"]}><Download /></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </MainLayout>
    )
}