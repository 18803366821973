import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import BaseFoundation from '../base/foundation';
import { handlePrevent, setFocusToFirstItem, setFocusToLastItem } from '../utils/a11y';
export default class DropdownFoundation extends BaseFoundation {
  handleVisibleChange(visible) {
    this._adapter.setPopVisible(visible);

    this._adapter.notifyVisibleChange(visible);
  }

  getMenuItemNodes(target) {
    var _context;

    const id = target.attributes['data-popupid'].value;
    const menuWrapper = document.getElementById(id); // if has dropdown item, the item must wrapped by li

    return menuWrapper ? _filterInstanceProperty(_context = _Array$from(menuWrapper.getElementsByTagName('li'))).call(_context, item => item.ariaDisabled === "false") : null;
  }

  setFocusToFirstMenuItem(target) {
    const menuItemNodes = this.getMenuItemNodes(target);
    menuItemNodes && setFocusToFirstItem(menuItemNodes);
  }

  setFocusToLastMenuItem(target) {
    const menuItemNodes = this.getMenuItemNodes(target);
    menuItemNodes && setFocusToLastItem(menuItemNodes);
  }

  handleKeyDown(event) {
    switch (event.key) {
      case ' ':
      case 'Enter':
        event.target.click();
        handlePrevent(event);
        break;

      case 'ArrowDown':
        this.setFocusToFirstMenuItem(event.target);
        handlePrevent(event);
        break;

      case 'ArrowUp':
        this.setFocusToLastMenuItem(event.target);
        handlePrevent(event);
        break;

      default:
        break;
    }
  }

}