import _isObject from "lodash/isObject";
import _findLastIndex from "lodash/findLastIndex";
import _isMap from "lodash/isMap";
import _includes from "lodash/includes";
import _get from "lodash/get";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import BaseFoundation from '../base/foundation';
import { strings } from './constants';
import { getRecordKey, genExpandedRowKey, getRecordChildren, expandBtnShouldInRow } from './utils';
export default class TableBodyFoundation extends BaseFoundation {
  init() {
    this.initVirtualizedData();
    this.initExpandBtnShouldInRow();
  }

  destroy() {
    this.unobserveBodyResize();
  }

  initVirtualizedData(cb) {
    this._adapter.setVirtualizedData(this.flattenData(this.getProp('dataSource')), cb);
  }

  initExpandBtnShouldInRow(newExpandRelatedProps) {
    const props = this.getProps(); // TODO check: this._adapter.getProps -> this.getProps

    const cachedExpandBtnShouldInRow = expandBtnShouldInRow(props);

    this._adapter.setCachedExpandBtnShouldInRow(cachedExpandBtnShouldInRow);

    if (!_isObject(newExpandRelatedProps) && !newExpandRelatedProps) {
      const expandRelatedProps = strings.EXPAND_RELATED_PROPS; // eslint-disable-next-line no-param-reassign

      newExpandRelatedProps = _mapInstanceProperty(expandRelatedProps).call(expandRelatedProps, key => _get(props, key, undefined));
    }

    this._adapter.setCachedExpandRelatedProps(newExpandRelatedProps);
  }

  flattenData() {
    let dataSource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let parentKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let childrenKeys = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const flattenData = [];
    const {
      rowKey,
      childrenRecordName,
      expandedRowRender,
      expandedRowKeys,
      groups
    } = this.getProps();

    if (level === 0 && _isMap(groups)) {
      _forEachInstanceProperty(groups).call(groups, (set, key) => {
        const firstIndex = _findIndexInstanceProperty(dataSource).call(dataSource, record => set.has(getRecordKey(record, rowKey)));

        if (firstIndex > -1) {
          const lastIndex = _findLastIndex(dataSource, record => set.has(getRecordKey(record, rowKey)));

          const expanded = _includes(expandedRowKeys, key);

          flattenData.push({
            key,
            level,
            sectionRow: true,
            group: set,
            groupKey: key,
            expanded
          });

          if (expanded) {
            flattenData.push(...this.flattenData(_sliceInstanceProperty(dataSource).call(dataSource, firstIndex, lastIndex + 1), level + 1, [...parentKeys], [...childrenKeys]));
          }
        }
      });
    } else {
      _forEachInstanceProperty(dataSource).call(dataSource, (record, index) => {
        const recordKey = getRecordKey(record, rowKey);
        const children = getRecordChildren(record, childrenRecordName);

        if (level) {
          childrenKeys.push(recordKey);
        }

        const item = {
          key: recordKey,
          record,
          level,
          parentKeys: [...parentKeys],
          childrenKeys: [...childrenKeys]
        };
        flattenData.push(item);
        const extras = [];

        if (_includes(expandedRowKeys, recordKey)) {
          if (_Array$isArray(children) && children.length) {
            extras.push(...this.flattenData(children, level + 1, [...item.parentKeys], [...item.childrenKeys]));
          } else if (expandedRowRender) {
            extras.push({
              key: genExpandedRowKey(recordKey),
              level,
              expandedRow: true,
              record
            });
          }

          flattenData.push(...extras);
        }
      });
    }

    return flattenData;
  }
  /**
   * Use ResizeObserver to monitor changes in the size of the body content area, and notify Table to recalculate if it changes. columns #1219
   * (Only monitor the scroll.y scene, other scenes are not monitored, because the header of the scroll.y scene is a separate table, and a scrollbar column will be inserted)
   */


  observeBodyResize(bodyDOM) {
    const {
      scroll
    } = this.getProps(); // TODO check: this._adapter.getProps -> this.getProps

    if (_get(scroll, 'y')) {
      return this._adapter.observeBodyResize(bodyDOM);
    }
  }

  unobserveBodyResize() {
    return this._adapter.unobserveBodyResize();
  }

}