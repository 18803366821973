import _isSet from "lodash/isSet";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";

/* istanbul ignore next */
export function copySet(setA) {
  return new _Set(setA);
}
/**
 * Set deduplicate
 * @param {*} setA
 * @param {*} setB
 */

export function pullAll(setA, setB) {
  if (setA === setB) {
    setB = copySet(setB);
  }

  if (_isSet(setA) && setA.size && _isSet(setB) && setB.size) {
    for (const item of setB) {
      if (setA.has(item)) {
        setA.delete(item);
      }
    }

    return setA;
  }

  return setA;
}