import _get from "lodash/get";

const log = function (text) {
  if (_get(process, 'env.NODE_ENV') === 'development') {
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    console.log(text, ...rest);
  }
};

export default log;