import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import { numbers as tooltipNumbers } from '../tooltip/constants';
import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  PREFIX: "".concat(BASE_CLASS_PREFIX, "-popover"),
  ARROW: "".concat(BASE_CLASS_PREFIX, "-popover-icon-arrow")
};
const strings = {
  POSITION_SET: ['top', 'topLeft', 'topRight', 'left', 'leftTop', 'leftBottom', 'right', 'rightTop', 'rightBottom', 'bottom', 'bottomLeft', 'bottomRight', 'leftTopOver', 'rightTopOver'],
  TRIGGER_SET: ['hover', 'focus', 'click', 'custom'],
  DEFAULT_ARROW_STYLE: {
    borderOpacity: '1',
    backgroundColor: 'var(--semi-color-bg-3)',
    // borderColor: 'var(--semi-color-shadow)',
    borderColor: 'var(--semi-color-border)'
  }
};
const numbers = {
  ARROW_BOUNDING: _Object$assign(_Object$assign({}, tooltipNumbers.ARROW_BOUNDING), {
    offsetY: 6,
    offsetX: 0,
    height: 8
  }),
  SPACING: 4,
  SPACING_WITH_ARROW: 10,
  DEFAULT_Z_INDEX: 1030
};
export { cssClasses, strings, numbers };