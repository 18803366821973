import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import BaseFoundation from '../base/foundation';
export default class CollapseFoundation extends BaseFoundation {
  constructor(adapter) {
    super(_Object$assign({}, adapter));
  }

  initActiveKey() {
    const {
      defaultActiveKey,
      activeKey,
      accordion
    } = this.getProps();
    let activeKeyList = activeKey ? activeKey : defaultActiveKey;

    if (accordion) {
      activeKeyList = _Array$isArray(activeKeyList) ? activeKeyList[0] : activeKeyList;
    }

    if (activeKeyList && activeKeyList.length) {
      activeKeyList = _Array$isArray(activeKeyList) ? activeKeyList : [activeKeyList];
      return activeKeyList;
    }

    return []; // this._adapter.initActiveSet(activeKeyList);
  }

  handleChange(newKey, e) {
    const {
      activeKey,
      accordion
    } = this.getProps();
    const {
      activeSet
    } = this.getStates();
    let newSet = new _Set(activeSet);

    if (newSet.has(newKey)) {
      newSet.delete(newKey);
    } else {
      if (accordion) {
        newSet = new _Set([newKey]);
      } else {
        newSet.add(newKey);
      }
    }

    this._adapter.handleChange([..._valuesInstanceProperty(newSet).call(newSet)], e);

    if (typeof activeKey === 'undefined') {
      this._adapter.addActiveKey(newSet);
    }
  }

}