import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
// currently used to initiate the velocity style object to 0
export default function mapToZero(obj) {
  const ret = {};
  const objKeys = obj && _Object$keys(obj) || [];

  for (const key of objKeys) {
    ret[key] = 0;
  }

  return ret;
}