export const Tutorials = [
    {
        title: "How can I transfer my quotas?",
        link: "https://lake-reward-33f.notion.site/How-can-I-transfer-my-quotas-038b7786853748ab807f0e97584ef769"
    },
    {
        title: "Blind box purchase and application tutorial",
        link: "https://lake-reward-33f.notion.site/Blind-box-purchase-and-application-tutorial-295cc7d30e5743f9b7c3c6738098b0d9"
    },
    {
        title: "How to set up a wallet?",
        link: "https://lake-reward-33f.notion.site/How-to-set-up-a-wallet-5097d7d156dc4e91a57d7077c1427f96"
    },
    {
        title: "How to use the market to purchase name quotas?",
        link: "https://lake-reward-33f.notion.site/How-to-use-the-market-to-purchase-name-quotas-c19237022d9b4a1581eda349a307f62f"
    },
    {
        title: "How to sell quotas or IC names?",
        link: "https://lake-reward-33f.notion.site/How-to-sell-quotas-or-IC-names-a01e21e0dc074eea8d851125aea8823f"
    },
    {
        title: "How to apply quotas?",
        link: "https://lake-reward-33f.notion.site/How-to-apply-quotas-19ec5c9c89384dce9db181d26fbbf150"
    },
    {
        title: "Tutorial on set up personal homepage",
        link: "https://lake-reward-33f.notion.site/Tutorial-on-set-up-personal-homepage-12bcc11b5ac64468af2685acc5b607b2"
    },
    {
        title: "How to transfer controller",
        link: "https://lake-reward-33f.notion.site/How-to-transfer-controller-7ef32b67c7184f56a5d246c866dde18b"
    },
    {
        title: "How to transfer registrant",
        link: "https://lake-reward-33f.notion.site/How-to-transfer-registrant-50177f9427464140aca261be3f158693"
    },
    {
        title: "How to claim my project name",
        link: "https://lake-reward-33f.notion.site/How-to-claim-your-project-name-8938f1b4d0f04335b6ad78dc52536efe"
    },
    {
        title: "How many chars names are currently open for registration",
        link: "https://lake-reward-33f.notion.site/FAQ-b57912f63b204ea18405a9edcd0f66ac"
    },
    {
        title:"Surf the Decentralized Web",
        link:"https://lake-reward-33f.notion.site/Surf-the-Decentralized-Web-a3f2263c7b754913bb3f66969a343251"
    },
    {
        title:"Name Market Transaction Fee",
        link:"https://lake-reward-33f.notion.site/Name-Market-Transaction-Fee-ccd8a215fd194095bc1defa72dc221ea"
    },
    {
        title:"Can I reverse my account if I forget the phrase",
        link:"https://lake-reward-33f.notion.site/Can-I-reverse-my-account-if-I-forget-the-phrase-4e418c7eaf304e75b451aa3563a9de35"
    }
]