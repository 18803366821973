import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  PREFIX: BASE_CLASS_PREFIX + '-overflow-list'
};
const MODE_MAP = {
  COLLAPSE: 'collapse',
  SCROLL: 'scroll'
};
const BOUNDARY_MAP = {
  START: 'start',
  END: 'end'
};
const OVERFLOW_DIR = {
  NONE: 0,
  GROW: 1,
  SHRINK: 2
};
const strings = {
  BOUNDARY_SET: _Object$values(BOUNDARY_MAP),
  POSITION_SET: ['vertical', 'horizontal'],
  MODE_SET: _Object$values(MODE_MAP),
  MODE_MAP,
  BOUNDARY_MAP,
  OVERFLOW_DIR
};
const numbers = {};
export { cssClasses, strings, numbers };