import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _parseFloat from "@babel/runtime-corejs3/core-js-stable/parse-float";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import bezier from 'bezier-easing';

function minMax(val, min, max) {
  return Math.min(Math.max(val, min), max);
}

function parseEasingParameters(string) {
  var _context;

  const match = /\(([^)]+)\)/.exec(string);
  return match ? _mapInstanceProperty(_context = match[1].split(',')).call(_context, p => _parseFloat(p)) : [];
} // Elastic easing adapted from jQueryUI http://api.jqueryui.com/easings/


function elastic() {
  let amplitude = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  let period = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.5;
  const a = minMax(amplitude, 1, 10);
  const p = minMax(period, 0.1, 2);
  return t => t === 0 || t === 1 ? t : -a * Math.pow(2, 10 * (t - 1)) * Math.sin((t - 1 - p / (Math.PI * 2) * Math.asin(1 / a)) * (Math.PI * 2) / p);
} // anime.js/src/index.js


export const easingMap = (() => {
  const names = ['Quad', 'Cubic', 'Quart', 'Quint', 'Sine', 'Expo', 'Circ', 'Back', 'Elastic']; // Approximated Penner equations http://matthewlein.com/ceaser/

  const curves = {
    In: [[0.55, 0.085, 0.68, 0.53]
    /* inQuad */
    , [0.55, 0.055, 0.675, 0.19]
    /* inCubic */
    , [0.895, 0.03, 0.685, 0.22]
    /* inQuart */
    , [0.755, 0.05, 0.855, 0.06]
    /* inQuint */
    , [0.47, 0.0, 0.745, 0.715]
    /* inSine */
    , [0.95, 0.05, 0.795, 0.035]
    /* inExpo */
    , [0.6, 0.04, 0.98, 0.335]
    /* inCirc */
    , [0.6, -0.28, 0.735, 0.045]
    /* inBack */
    , elastic
    /* inElastic */
    ],
    Out: [[0.25, 0.46, 0.45, 0.94]
    /* outQuad */
    , [0.215, 0.61, 0.355, 1.0]
    /* outCubic */
    , [0.165, 0.84, 0.44, 1.0]
    /* outQuart */
    , [0.23, 1.0, 0.32, 1.0]
    /* outQuint */
    , [0.39, 0.575, 0.565, 1.0]
    /* outSine */
    , [0.19, 1.0, 0.22, 1.0]
    /* outExpo */
    , [0.075, 0.82, 0.165, 1.0]
    /* outCirc */
    , [0.175, 0.885, 0.32, 1.275]
    /* outBack */
    , (a, p) => t => 1 - elastic(a, p)(1 - t)
    /* outElastic */
    ],
    InOut: [[0.455, 0.03, 0.515, 0.955]
    /* inOutQuad */
    , [0.645, 0.045, 0.355, 1.0]
    /* inOutCubic */
    , [0.77, 0.0, 0.175, 1.0]
    /* inOutQuart */
    , [0.86, 0.0, 0.07, 1.0]
    /* inOutQuint */
    , [0.445, 0.05, 0.55, 0.95]
    /* inOutSine */
    , [1.0, 0.0, 0.0, 1.0]
    /* inOutExpo */
    , [0.785, 0.135, 0.15, 0.86]
    /* inOutCirc */
    , [0.68, -0.55, 0.265, 1.55]
    /* inOutBack */
    , (a, p) => t => t < 0.5 ? elastic(a, p)(t * 2) / 2 : 1 - elastic(a, p)(t * -2 + 2) / 2
    /* inOutElastic */
    ]
  };
  const eases = {
    linear: [0.25, 0.25, 0.75, 0.75]
  };

  for (const coords of _Object$keys(curves)) {
    var _context2;

    _forEachInstanceProperty(_context2 = curves[coords]).call(_context2, (ease, i) => {
      eases['ease' + coords + names[i]] = ease;
    });
  }

  return eases;
})();
/**
 * get easing function
 * @param {string|Function} easing
 * @returns {Function}
 */

export default function getEasing(easing) {
  if (typeof easing === 'function') {
    return easing;
  }

  if (!easing || typeof easing !== 'string') {
    easing = 'linear';
  } else {
    easing = _trimInstanceProperty(easing).call(easing);
  }

  let name = easing.split('(')[0];
  const args = parseEasingParameters(easing);
  let ease;

  if (name === 'cubic-bezier' || name === 'cubicBezier') {
    return bezier(...(args.length ? args : easingMap.linear));
  } else {
    // eslint-disable-next-line eqeqeq
    if (!name || typeof name !== 'string' || typeof name === 'string' && easingMap[name] == null) {
      name = 'linear';
    }

    ease = easingMap[name];

    if (typeof ease === 'function') {
      return ease(...args);
    } else if (args.length) {
      return bezier(...args);
    } else {
      return bezier(...ease);
    }
  }
}