.footer{
  width: 100%;
  background: rgba(248, 246, 254, 1);
  padding: 3rem 0;
}
.social-media{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .icon{
    margin: 0 .5rem;
    width: 2rem;
    height: 2rem;
    padding: 0.45rem;
    font-size: 0;
    border-radius: 100%;
    color: #16042F;
    background-color: #ffffff;
    background-repeat: no-repeat;
    box-shadow: 8px 8px 24px 0px #E2DBF0, -8px -8px 24px 0px #FFFFFF;
    transition: color .3s;
    &:hover{
      color: #820FF0;
    }
  }
}


