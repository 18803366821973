import { BASE_CLASS_PREFIX } from '../base/constants';
const radioClasses = {
  PREFIX: "".concat(BASE_CLASS_PREFIX, "-radio"),
  INNER: "".concat(BASE_CLASS_PREFIX, "-radio-inner"),
  TEXT: "".concat(BASE_CLASS_PREFIX, "-radio-text"),
  INPUT: "".concat(BASE_CLASS_PREFIX, "-radio-input"),
  CHECKED: "".concat(BASE_CLASS_PREFIX, "-radio-checked"),
  DISABLED: "".concat(BASE_CLASS_PREFIX, "-radio-disabled"),
  BUTTON: "".concat(BASE_CLASS_PREFIX, "-radio-button")
};
const radioGroupClasses = {
  PREFIX: "".concat(BASE_CLASS_PREFIX, "-radioGroup"),
  INNER: "".concat(BASE_CLASS_PREFIX, "-radioGroup-inner"),
  TEXT: "".concat(BASE_CLASS_PREFIX, "-radioGroup-text"),
  INPUT: "".concat(BASE_CLASS_PREFIX, "-radioGroup-input"),
  CHECKED: "".concat(BASE_CLASS_PREFIX, "-radioGroup-checked"),
  DISABLED: "".concat(BASE_CLASS_PREFIX, "-radioGroup-disabled")
};
const strings = {
  DIRECTION_SET: ['horizontal', 'vertical'],
  DEFAULT_DIRECTION: 'horizontal',
  MODE: ['advanced', ''],
  TYPE_DEFAULT: 'default',
  TYPE_BUTTON: 'button',
  TYPE_CARD: 'card',
  TYPE_PURECARD: 'pureCard',
  BUTTON_SIZE: ['middle', 'small', 'large']
};
const numbers = {};
export { radioClasses, radioGroupClasses, strings, numbers };