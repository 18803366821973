import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _lastIndexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/last-index-of";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import BaseFoundation from '../base/foundation';
import { strings } from './constants';
const Boundary = strings.BOUNDARY_MAP;
const OverflowDirection = strings.OVERFLOW_DIR;

class OverflowListFoundation extends BaseFoundation {
  constructor(adapter) {
    super(_Object$assign({}, adapter));
    this.previousY = undefined;

    this.isScrollMode = () => {
      const {
        renderMode
      } = this.getProps();
      return renderMode === 'scroll';
    };
  }

  getOverflowItem() {
    const {
      items
    } = this.getProps();
    const {
      visibleState,
      overflow
    } = this.getStates();

    if (!this.isScrollMode()) {
      return overflow;
    }

    const visibleStateArr = _mapInstanceProperty(items).call(items, _ref => {
      let {
        key
      } = _ref;
      return Boolean(visibleState.get(key));
    });

    const visibleStart = _indexOfInstanceProperty(visibleStateArr).call(visibleStateArr, true);

    const visibleEnd = _lastIndexOfInstanceProperty(visibleStateArr).call(visibleStateArr, true);

    const overflowList = [];
    overflowList[0] = visibleStart >= 0 ? _sliceInstanceProperty(items).call(items, 0, visibleStart) : [];
    overflowList[1] = visibleEnd >= 0 ? _sliceInstanceProperty(items).call(items, visibleEnd + 1, items.length) : items;
    return overflowList;
  }

  handleIntersect(entries) {
    const visibleState = _cloneDeep(this.getState('visibleState'));

    const res = {};

    _forEachInstanceProperty(entries).call(entries, entry => {
      const itemKey = _get(entry, 'target.dataset.scrollkey');

      const visible = entry.isIntersecting;
      res[itemKey] = entry;
      visibleState.set(itemKey, visible);
    });

    let someItemVisible = false;

    for (const value of _valuesInstanceProperty(visibleState).call(visibleState)) {
      if (value) {
        someItemVisible = true;
        break;
      }
    } // Any item is visible, indicating that the List is visible


    const wholeListVisible = someItemVisible; // If scrolling in the vertical direction makes the List invisible, no processing is required. 
    // If this.previousY is undefined, it means that the List is mounted for the first time and will not be processed.

    const [entry1] = entries;
    const currentY = entry1.boundingClientRect.y;

    if (!wholeListVisible && this.previousY !== undefined && currentY !== this.previousY) {
      this.previousY = currentY;
      return;
    }

    this.previousY = currentY;

    this._adapter.updateVisibleState(visibleState);

    this._adapter.notifyIntersect(res);
  }

  handlePartition(growing) {
    const {
      direction,
      overflow,
      lastOverflowCount,
      visible
    } = this.getStates();
    const {
      minVisibleItems,
      collapseFrom,
      items
    } = this.getProps();
    let updateState = {};

    if (growing === OverflowDirection.NONE) {
      updateState = {
        direction: OverflowDirection.NONE
      };
    }

    if (growing === OverflowDirection.GROW) {
      const updatedOverflowCount = direction === OverflowDirection.NONE ? overflow.length : lastOverflowCount;
      updateState = {
        direction: OverflowDirection.GROW,
        lastOverflowCount: updatedOverflowCount,
        overflow: [],
        visible: items
      };
    }

    if (growing === OverflowDirection.SHRINK && visible.length > minVisibleItems) {
      const collapseFromStart = collapseFrom === Boundary.START;

      const newVisible = _sliceInstanceProperty(visible).call(visible);

      const next = collapseFromStart ? newVisible.shift() : newVisible.pop();

      if (next !== undefined) {
        updateState = {
          // set SHRINK mode unless a GROW is already in progress.
          // GROW shows all items then shrinks until it settles, so we
          // preserve the fact that the original trigger was a GROW.
          direction: direction !== OverflowDirection.GROW ? OverflowDirection.SHRINK : direction,
          overflow: collapseFromStart ? [...overflow, next] : [next, ...overflow],
          visible: newVisible
        };
      }
    }

    this._adapter.updateStates(updateState);
  }

}

export default OverflowListFoundation;