import * as styles from './index.module.less'
import classNames from "classnames";
import Twitter from 'jsx:./image/twitter.svg';
import Discord from 'jsx:./image/discord.svg';
import Medium  from 'jsx:./image/medium.svg';
import Github  from 'jsx:./image/github.svg';
import PressKit  from 'jsx:./image/presskit.svg';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles['social-media']}>
        <a href="https://twitter.com/ic_naming" target='_blank' rel="noreferrer" className={classNames(styles.icon)}><Twitter /></a>
        <a href="https://medium.com/@icnaming" target='_blank' rel="noreferrer" className={classNames(styles.icon)}><Medium /></a>
        <a href="https://github.com/IC-Naming" target='_blank' rel="noreferrer" className={classNames(styles.icon)}><Github/></a>
        <a href="https://discord.gg/FJ63ckXn6n" target='_blank' rel="noreferrer" className={classNames(styles.icon)}><Discord /></a>
        <a href="/press-kit" className={classNames(styles.icon)}><PressKit /></a>
      </div>
    </footer>
  )
}


