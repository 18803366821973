import BaseFoundation from '../base/foundation';
import warning from '../utils/warning';
export default class RadioFoundation extends BaseFoundation {
  constructor() {
    super(...arguments);

    this.handleFocusVisible = event => {
      const {
        target
      } = event;

      try {
        if (target.matches(':focus-visible')) {
          this._adapter.setFocusVisible(true);
        }
      } catch (error) {
        warning(true, 'Warning: [Semi Radio] The current browser does not support the focus-visible');
      }
    };

    this.handleBlur = () => {
      this._adapter.setFocusVisible(false);
    };
  }

  init() {
    const {
      children,
      extra,
      extraId,
      addonId
    } = this._adapter.getProps();

    if (children && !addonId) {
      this._adapter.setAddonId();
    }

    if (extra && !extraId) {
      this._adapter.setExtraId();
    }
  }

  setHover(hover) {
    this._adapter.setHover(hover);
  }

}